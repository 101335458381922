<template>
    <div class="mb-5">
        <div class="w-100 text-center">
            <h1 class="h2">Marcas destacadas</h1>
        </div>
        <div class="d-flex flex-wrap">
            <div class="col-12 mt-5">
                <subline-selector v-model="line" add-root enabled />
            </div>
        </div>
        <div class="row my-5 d-flex justify-content-center">
            <vs-button @click="listHighlightBrandsComponent">
                Consultar marcas destacadas
            </vs-button>
        </div>

        <div v-show="isQuery">
            <h3>Busca las marcas que quieres agregar a los destacados</h3>
            <brands-search v-model="brand" @input="validatePushItems" />
            <items-cards v-model="items" class="mt-2" />
        </div>
        <div
            v-if="items.length"
            class="row mt-2 mb-5 d-flex justify-content-center"
        >
            <vs-button @click="updateHighlightBrandsComponent">
                Actualizar marcas destacadas
            </vs-button>
        </div>
        <div class="h-20"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BrandsSearch from "@/components/brands/BrandsSearch.vue";
import SublineSelector from "@/components/lines/SublineSelector.vue";
import ItemsCards from "@/components/utils/ItemsCards.vue";

export default {
    name: "HighlightBrands",
    components: {
        SublineSelector,
        ItemsCards,
        BrandsSearch
    },
    data: () => ({
        loaderInstance: null,
        isLoading: true,
        items: [],
        isQuery: false,
        brand: { name: "", slug: "" },
        line: {
            slug: "",
            name: ""
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("brand:ListHighlight", "/app");
    },
    methods: {
        ...mapActions("brands", [
            "listHighlightBrands",
            "updateHighlightBrands"
        ]),
        async listHighlightBrandsComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circle" });
            try {
                this.items = await this.listHighlightBrands({
                    lineSlug: this.line?.slug
                });
                this.isQuery = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async updateHighlightBrandsComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circle" });
            try {
                await this.updateHighlightBrands({
                    lineSlug: this.line?.slug,
                    brandsSlugs: this.items.map((item) => item.slug)
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        validatePushItems(newItem) {
            const index = this.items.findIndex(
                (item) => newItem.id === item.id
            );
            if (index === -1) {
                this.items.unshift(newItem);
            }
        }
    }
};
</script>
