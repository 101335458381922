<template>
    <div>
        <div class="d-flex flex-wrap">
            <div
                v-for="(item, index) in itemsCurrent"
                :key="item.id"
                class="col-6 col-md-4 col-lg-3 col-xl-3 px-2 py-4 position-relative"
            >
                <div class="position-absolute top-0">
                    <vs-button danger icon @click="remove(index)">🗑</vs-button>
                </div>
                <img
                    v-if="item.images"
                    v-img:group
                    loading="lazy"
                    :src="item.images[0].url | image"
                    :alt="item.images[0].alt"
                    class="rounded border border-primary w-100"
                />
                <div
                    class="bg-info d-flex align-items-center rounded max-w-24 pl-2 py-2"
                >
                    <div class="d-flex flex-wrap">
                        <div class="col-12 mt-2 d-flex justify-content-between">
                            <div class="d-flex flex-column">
                                <router-link
                                    :to="`/app/${entity}/detail/${item.id}`"
                                    class="btn-a p-0"
                                >
                                    {{ item.name }}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-12 mt-2 d-flex flex-column">
                            <strong class="">Slug </strong>
                            <small>
                                {{ item.slug }}
                            </small>
                        </div>
                        <div class="col-12 mt-2 d-flex flex-column">
                            <strong class="">Descripción </strong>
                            <small>
                                {{ item.shortDescription | cut(36) }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DEFAULT_IMAGE_URL } from "@/common/constants";

export default {
    name: "ItemsCards",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            required: true,
            default: () => [
                {
                    id: 72,
                    name: "Item Name",
                    slug: "item-slug",
                    shortDescription: "ShortDescription",
                    images: [
                        {
                            id: 128,
                            url: DEFAULT_IMAGE_URL,
                            alt: "Bota de niño de sapito",
                            order: {
                                order: 0
                            }
                        }
                    ]
                }
            ]
        },
        entity: {
            type: String,
            default: () => "brands",
            required: false
        }
    },
    emits: ["change"],
    data: () => ({}),
    methods: {
        remove(fromIndex) {
            let items = [...this.itemsCurrent];
            items.splice(fromIndex, 1);
            this.$emit("change", items);
        }
    }
};
</script>
